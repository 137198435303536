import {useEffect, useState} from 'react';
import {
    getUserOrganizationsPlain,
    getUserOrganizationsWithNotesAndCourses
} from "../../Services/api";
import {getAdminOrganizations} from "../../Utils/User/UserLib";
import {get, map} from 'lodash';
import {i18n} from "../../Utils/i18n/i18n";

export const useUserAdminOrgs = ({withAllOption, withCoursesAndSignDocs}) => {
    const [organizationsOptions, setOrganizationsOptions] = useState();
    const [organizations, setOrganizations] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const fetchOrgs = async () => {
        setIsLoading(true)
        let orgs
        if (withCoursesAndSignDocs) {
            orgs = await getUserOrganizationsWithNotesAndCourses();
        } else {
            orgs = await getUserOrganizationsPlain();
        }
        orgs = getAdminOrganizations({organizationsWithPermission: orgs});
        let orgOptions = map(orgs, (org) => {
            return {
                value: get(org, 'organization.id'),
                orgLogo: get(org, 'organization.orgLogo'),
                text: get(org, 'organization.orgName')
            };
        });
        if (withAllOption) {
            orgOptions.unshift({value: 'all', text: i18n.translate('allOrganizations')});
        }
        setOrganizationsOptions(orgOptions);
        setOrganizations(orgs);
        setIsLoading(false);
    };

    const triggerReloadOrganizations = () => {
        fetchOrgs();
    }

    useEffect(() => {
        fetchOrgs();
    }, []);

    const isOrgsLoading = isLoading
    const adminOrganizationOptions = organizationsOptions
    const adminOrganizations = organizations
    return {adminOrganizationOptions, adminOrganizations, isOrgsLoading, triggerReloadOrganizations};
};