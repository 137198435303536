import React, {useEffect, useState, useRef} from 'react';
import './CanvaEmbed.scss'
const CanvaEmbed = ({ canvaLink, onReadingCompleted }) => {
    const iframeRef = useRef(null);

    useEffect(() => {
        onReadingCompleted && onReadingCompleted()
    }, []);

    return (
        <div className="canvaEmbed">
            <iframe
                ref={iframeRef}
                src={canvaLink + '?embed'}
                width="100%"
                height="100%"
                frameBorder="0"
                allowFullScreen
                title="Canva Embed"
            />
        </div>
    );
};

export default CanvaEmbed;