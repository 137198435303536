import './CreateEditCourseModal.scss';
import {i18n} from "../../../Utils/i18n/i18n";
import {FormInput} from "../../Form/Input/FormInput";
import React, {useContext, useEffect, useRef, useState} from "react";
import {Button} from "../../Form/Button/Button";
import {buttonColors} from "../../Pickers/InputTheme";
import {
    SA_createSignDoc,
    SA_getAllCompanies,
    SA_getAllOrganizationsForSignDoc,
    SA_uploadSignDocZipFileAPI
} from "../../../Services/SA_api";
import {FileUploadBox} from "../../Smart/FileUploadBox/FileUploadBox";
import {DataGridWrapper} from "../../../Utils/DataGridWrapper/DataGridWrapper";
import {GridReactContext, GridReactContextProvider} from "../../../Utils/DataGridWrapper/GridContext";
import {
    adminNewCourseConvertData,
    adminNewCourseFilterModel,
    getAdminNewCourseColumns
} from "./CreateNewCourseModalLib";
import {cloneDeep, find, get, map} from "lodash";
import {Modal} from "../../Smart/Modal/Modal";
import {imageOptionsArray} from "./CreateEditCourseLib";
import {ImageSelectGallery} from "./ImageSelectGallery/ImageSelectGallery";
import {SignDocTypes} from "../../SignDoc/SignDocLib";

const imageOptions = cloneDeep(imageOptionsArray)

export const CreateNewCourseModal = ({createCourseModalOpen, closeCreateCourseModal}) => {
    const [docName, setDocName] = useState()
    const [courseDescription, setCourseDescription] = useState()
    const [signDocId, setSignDocId] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [companies, setCompanies] = useState()
    const [zipFileUploaded, setZipFileUploaded] = useState(false)
    const [selectedImageUrl, setSelectedImageUrl] = useState(imageOptions[0])
    const selectedCompany = useRef(null)

    const gridContext = useContext(GridReactContext)
    const editCourseName = (e) => {
        setDocName(e.target.value)
    }
    const editCourseDescription = (e) => {
        setCourseDescription(e.target.value)
    }
    const init = async () => {

    }
    const apiToGetData = async ({paginationModel, filterModel}) => {
        return SA_getAllOrganizationsForSignDoc({paginationModel, filterModel, signDocId: signDocId})
    }
    useEffect(() => {
        init()
    }, []);
    const onCreateCourse = async () => {
        setIsLoading(true)
        const [newSignDoc, companies] = await Promise.all([SA_createSignDoc({
            docName,
            description: courseDescription,
            imgSrc: selectedImageUrl,
            type: SignDocTypes.REGULAR_COURSE
        }), SA_getAllCompanies()])
        const selectBoxCompanies = map(companies, (company) => {
            return {
                value: get(company, 'id'),
                text: get(company, 'companyName')
            }
        })
        selectedCompany.current = find(selectBoxCompanies, {value: get(gridContext.filterModel, 'filterValues.companyId')})
        setCompanies(selectBoxCompanies)
        setSignDocId(newSignDoc.id)
        setDocName(newSignDoc.docName)
        setCourseDescription(newSignDoc.description)
        setIsLoading(false)
        gridContext.triggerTableRefresh()
    }

    const uploadCourseZip = async ({files, onProgress}) => {
        return SA_uploadSignDocZipFileAPI({files, onProgress, signDocId})
    }
    const getCourseColumnsWithCourseId = async () => {
        return getAdminNewCourseColumns({signDocId})
    }

    const onZipFileCompletedUpload = () => {
        setZipFileUploaded(true)
    }

    const onSelectedImageUrl = (imageUrl) => () => {
        setSelectedImageUrl(imageUrl)
    }


    return <Modal
        open={createCourseModalOpen}
        onClose={closeCreateCourseModal}>
        <div className="CreateEditCourse modalWrapper">
            <div className="modalTitle">{i18n.translate('superAdmin.createNewCourse.modalTitle')}</div>
            <div className="modalContent">
                <div className="upperRow">
                    <div className="courseDetails">
                        <FormInput
                            label={i18n.translate('superAdmin.createNewCourse.docName')}
                            className=""
                            value={docName}
                            onChange={editCourseName}
                        />
                        <FormInput
                            label={i18n.translate('superAdmin.createNewCourse.courseDescription')}
                            className=""
                            value={courseDescription}
                            onChange={editCourseDescription}
                        />



                    </div>

                    {signDocId ? <div className={`courseZipFile`}>
                        <FileUploadBox uploadFiles={uploadCourseZip} onFileUploaded={onZipFileCompletedUpload} maxSize={600 * 1024 * 1024}>
                            {i18n.translate('superAdmin.createNewCourse.dropZipHere')}
                        </FileUploadBox>
                    </div> : ''}
                </div>
                <ImageSelectGallery selectedImageUrl={selectedImageUrl} onSelectedImageUrl={onSelectedImageUrl} imageOptions={imageOptions}/>
                {!signDocId ? <div className="saveContainer">
                    <Button
                        color={buttonColors.GREY}
                        onClick={onCreateCourse}
                        disabled={docName === '' || !docName || courseDescription === '' || !courseDescription}
                        text={i18n.translate('superAdmin.createNewCourse.create')}
                    />
                </div> : ''}
                <div className="organizationsTable">
                    {signDocId && companies && zipFileUploaded ? <GridReactContextProvider>
                        <DataGridWrapper
                            filterModel={adminNewCourseFilterModel({companies, companyId:  get(selectedCompany, 'current.value') || get(companies, '[0].value')})}
                            disableColumnFilter
                            getColumns={getCourseColumnsWithCourseId}
                            apiToGetData={apiToGetData}
                            dataConverter={adminNewCourseConvertData}
                        />
                    </GridReactContextProvider> : ''}
                </div>
            </div>
            <div className="modalFooter">
                <Button
                    color={buttonColors.YELLOW}
                    onClick={closeCreateCourseModal}
                    text={i18n.translate('general.close')}
                />
            </div>
        </div>
    </Modal>
}