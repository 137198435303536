import './CreateEditUserModal.scss'
import {i18n} from "../../../Utils/i18n/i18n";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import React, {useContext, useEffect, useState} from "react";
import {Modal} from "../../Smart/Modal/Modal";
import {
    createUsersForOrganizations,
    getCompanyPrefixAPI,
    getUserAPI,
    getUserOrganizationsPlain,
    updateUserAPI
} from "../../../Services/api";
import {getAdminOrganizations} from "../../../Utils/User/UserLib";
import {filter, find, get, map} from "lodash";
import {SelectBox} from "../../Pickers/SelectBox/SelectBox";
import {GridReactContext} from "../../../Utils/DataGridWrapper/GridContext";
import {FormInput} from "../../Form/Input/FormInput";
import {Button} from "../../Form/Button/Button";
import {buttonColors} from "../../Pickers/InputTheme";
import {FormControlLabel, Switch} from "@mui/material";
import {MessageTypeSelectionButtons} from "../../Notifications/MessageTypeSelectionButtons/MessageTypeSelectionButtons";
import {FeatureFlags, MessageTypes} from "../../../Utils/Constants";
import {isEmailValid, sanitizePhoneNumber, validatePhoneNumberAndFormat} from "../../../Utils/functions";
import {CompanyReactContext} from "../../../contexts/CompanyContext";
import {MultiSelectAutocomplete} from "../../Form/AutoComplete/AutoComplete";
import {useUserAdminOrgs} from "../../Hooks/UseUserAdminOrgs";


export const CreateEditUserModal = ({
                                        createEditUserModalOpen,
                                        closeCreateEditUserModal,
                                        onSuccess,
                                        editMode,
                                        userId
                                    }) => {
    const {adminOrganizationOptions, adminOrganizations, isOrgsLoading} = useUserAdminOrgs({})
    const [isLoading, setIsLoading] = React.useState(false)
    const [selectedOrganizations, setSelectedOrganizations] = useState([])
    const [selectedOrganization, setSelectedOrganization] = React.useState()
    const [companyPrefix, setCompanyPrefix] = React.useState()
    const gridContext = React.useContext(GridReactContext)
    const [firstName, setFirstName] = React.useState('')
    const [lastName, setLastName] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [identifier, setIdentifier] = React.useState('')
    const [phone, setPhone] = React.useState('')
    const [sendWelcomeMessage, setSendWelcomeMessage] = React.useState(false)
    const [selectedMessagingTypes, setSelectedMessagingTypes] = React.useState([MessageTypes.SMS])
    const companyContext = useContext(CompanyReactContext)

    const onSelectedMessagingTypeChange = (messagingType) => {
        //if it is the only one selected do not remove it

        if (selectedMessagingTypes.includes(messagingType) && selectedMessagingTypes.length > 1) {
            setSelectedMessagingTypes(selectedMessagingTypes.filter((type) => type !== messagingType))
        } else {
            let messagingTypes = [...selectedMessagingTypes, messagingType]
            //uniq array
            messagingTypes = messagingTypes.filter((value, index, self) => self.indexOf(value) === index)
            setSelectedMessagingTypes(messagingTypes)
        }
    }
    const getCompanyPrefix = async ({organizationId}) => {
        const companyPrefix = await getCompanyPrefixAPI()
        setCompanyPrefix(companyPrefix)
    }

    const editFirstName = (e) => {
        setFirstName(e.target.value)
    }
    const editLastName = (e) => {
        setLastName(e.target.value)
    }
    const editEmail = (e) => {
        setEmail(e.target.value)
    }
    const editIdentifier = (e) => {
        setIdentifier(e.target.value)
    }
    const editPhone = (e) => {
        setPhone(e.target.value)
        setIdentifier(sanitizePhoneNumber(e.target.value))
    }


    const innerOnSelectOrgChange = async (value) => {
        setIsLoading(true)
        await getCompanyPrefix({organizationId: selectedOrganization})
        setSelectedOrganization(value)
        setIsLoading(false)
    }
    const onSelectOrgChange = (e) => {
        innerOnSelectOrgChange(e.target.value)
    }

    const fetchUser = async () => {
        const user = await getUserAPI({userId})
        setFirstName(user.firstName)
        setLastName(user.lastName)
        setEmail(user.email)
        setIdentifier(user.identifier)
        setPhone(user.phone)
        user.organizations = filter(user.organizations, org => find(adminOrganizationOptions, (adminOrg) => adminOrg.value === org.OrganizationId))
        const userOrgs = map(user.organizations, org => {
            return find(adminOrganizationOptions, (adminOrg) => adminOrg.value === org.OrganizationId)
        })
        setSelectedOrganizations(userOrgs)
    }

    const init = async () => {
        setIsLoading(true)
        if(!isOrgsLoading && adminOrganizationOptions){
            await getCompanyPrefix({organizationId: get(adminOrganizationOptions, '[1].value')})
            if (editMode) {
                await fetchUser()
            }
        }
        setIsLoading(false)
    }

    useEffect(() => {
        init()
    }, [isOrgsLoading]);

    const createOrUpdateUser = async () => {
        const user = {
            firstName,
            lastName,
            email,
            identifier: sanitizePhoneNumber(phone),
            phone: sanitizePhoneNumber(phone)
        }
        let sendWelcomeMessageData;
        if (sendWelcomeMessage) {
            sendWelcomeMessageData = {
                messageTypes: selectedMessagingTypes
            }
        }
        await createUsersForOrganizations({
            organizationIds: map(selectedOrganizations, 'value'),
            users: [user],
            sendWelcomeMessage: sendWelcomeMessageData
        })
    }
    const canSave = () => {
        let errors = []
        if (!firstName) {
            errors.push('firstName')
        }
        if (!lastName) {
            errors.push('lastName')
        }
        if (!identifier) {
            errors.push('identifier')
        }
        if (email) {
            if (!isEmailValid(email)) {
                errors.push('email')
            }
        }
        if (phone) {
            if (!validatePhoneNumberAndFormat(sanitizePhoneNumber(phone))) {
                errors.push('phone')
            }
        }
        if (sendWelcomeMessage && selectedMessagingTypes.length > 0) {
            if (selectedMessagingTypes.includes(MessageTypes.SMS) && !validatePhoneNumberAndFormat(sanitizePhoneNumber(phone))) {
                errors.push('phone')
            }
            if (selectedMessagingTypes.includes(MessageTypes.EMAIL) && !isEmailValid(email)) {
                errors.push('email')
            }
        }
        return errors
    }
    const onSave = async () => {
        setIsLoading(true)
        try {
            await createOrUpdateUser()
        } catch (e) {
            console.log(e)
        } finally {
            setIsLoading(false)
            gridContext.triggerTableRefresh()
            onSuccess()
        }

    }

    const onSendWelcomeMessageChange = (e) => {
        setSendWelcomeMessage(e.target.checked)
    }
    const getFilteredOrgs = ({query}) => {
        return filter(adminOrganizationOptions,org => org.text.toLowerCase().includes(query.toLowerCase()))
    }
    const onSelectOrgsChange = async (selectedOrganizations) => {
        setSelectedOrganizations(selectedOrganizations)
    }

    const errors = canSave()
    return <Modal
        open={createEditUserModalOpen}
        onClose={closeCreateEditUserModal}>
        <div className="CreateEditUserModal modalWrapper">
            <div className="modalTitle">
                <div>{editMode ? `${i18n.translate('createEditUserModal.titleEdit')} ${firstName} ${lastName}` : i18n.translate('createEditUserModal.titleCreate')}</div>
            </div>
            <div className="modalContent">
                {isLoading && <LoadingSpinner/>}

                <div className="selectWrapper">
                    {adminOrganizationOptions && adminOrganizationOptions.length && !isLoading ? <MultiSelectAutocomplete
                        selectAllText={i18n.translate('editAdminUserModal.selectAllOrgs')}
                        enableSelectAll
                        label={i18n.translate('editAdminUserModal.selectOrganizationalUnits')}
                        fetchOptions={getFilteredOrgs}
                        initialSelectedValues={selectedOrganizations}
                        onSelectionChange={onSelectOrgsChange}/> : ''}
                </div>
                <div className="inputsWrapper">
                    <FormInput
                        required
                        error={errors.includes('firstName')}
                        label={i18n.translate('createEditUserModal.firstName')}
                        className=""
                        value={firstName}
                        onChange={editFirstName}
                    />
                    <FormInput
                        required
                        error={errors.includes('lastName')}
                        label={i18n.translate('createEditUserModal.lastName')}
                        className=""
                        value={lastName}
                        onChange={editLastName}/>
                    <FormInput
                        required={sendWelcomeMessage && selectedMessagingTypes.includes(MessageTypes.EMAIL)}
                        showErrorWhenEmpty={true}
                        error={errors.includes('email')}
                        label={i18n.translate('createEditUserModal.email')}
                        className=""
                        value={email}
                        onChange={editEmail}/>
                    <FormInput
                        required
                        showErrorWhenEmpty={true}
                        error={errors.includes('phone')}
                        label={i18n.translate('createEditUserModal.phone')}
                        className=""
                        value={phone}
                        onChange={editPhone}/>

                    <div className="userIdentifierWithPrefix">
                        <FormInput
                            required
                            error={errors.includes('identifier')}
                            disabled
                            label={i18n.translate('createEditUserModal.identifier')}
                            className=""
                            value={identifier}
                            onChange={() => {}}
                        />
                        {!editMode ? <div className="inputPrefix">{`${companyPrefix}-`}</div> : ''}
                    </div>
                    {companyContext.featureFlags[FeatureFlags.SEND_WELCOME_MESSAGE] ?
                        <FormControlLabel control={<Switch
                            checked={sendWelcomeMessage}
                            onChange={onSendWelcomeMessageChange}
                        />} label={i18n.translate('createEditUserModal.sendWelcomeMessage')}/> : ''}

                    {sendWelcomeMessage ? <MessageTypeSelectionButtons
                            multiple={true}
                            onSelectedMessageTypeChange={onSelectedMessagingTypeChange}
                            selectedMessagingTypes={selectedMessagingTypes}
                            // excludeMessageTypes={[MessageTypes.WHATSAPP]}
                        />
                        : ''}
                </div>
                {!editMode ? <div className="userMessage">
                    {i18n.translate('createEditUserModal.userWillBeEditedIfPhoneExists')}
                </div> : ''}
            </div>
            <div className="modalFooter">
                <Button
                    color={buttonColors.YELLOW}
                    onClick={closeCreateEditUserModal}
                    text={i18n.translate('general.close')}
                />
                <Button
                    disabled={errors.length > 0}
                    color={buttonColors.GREY}
                    onClick={onSave}
                    text={i18n.translate(`createEditUserModal.save`)}
                />
            </div>
        </div>
    </Modal>
}