import React, { useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import { map } from 'lodash';
import './RoutingTabs.scss'; // Import the RoutingTabs.scss file
import { Colors } from '../styles/Colors';
import {UserReactContext} from "../../contexts/UserContext";

export const LocalTabs = ({ tabs }) => {
    const [currentTab, setCurrentTab] = useState(tabs[0].value);
    const userCntext = React.useContext(UserReactContext);

    const handleChange = (event, newValue) => {
        setCurrentTab(newValue);
    };
    const validTabs = tabs.filter(tab => {
        if (tab.onlyForCompanyAdmin) {
            return userCntext.user?.isCompanyAdmin;
        }
        return true;
    });

    return (
        <div className="Tabs"> {/* Use the same class name as in RoutingTabs */}
            <Tabs
                value={currentTab}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
            >
                {map(validTabs, (tab) => (
                    <Tab
                        label={tab.label}
                        key={tab.value}
                        value={tab.value}
                        sx={{
                            textTransform: 'none',
                            fontWeight: 'bold',
                            fontSize: '24px',
                            fontFamily: 'OpenSansCond',
                            '&.Mui-selected': {
                                color: Colors['$menuItemText'],
                            },
                        }}
                    />
                ))}
            </Tabs>
            <div className="routeContent"> {/* Use the same class name as in RoutingTabs */}
                {map(tabs, (tab) => (
                    tab.value === currentTab && <div key={tab.value}>{tab.content}</div>
                ))}
            </div>
        </div>
    );
};