import React from "react";
import {isEmpty, keys, map} from "lodash";
import {get} from "lodash";
import {createUsersForOrganizations} from "../../../Services/api";
import {GridReactContext} from "../../../Utils/DataGridWrapper/GridContext";

export const ImportUsersReactContext = React.createContext({
    canSubmit: false,
    usersToUploadData: {}
});


export const ImportUserContextProvider = ({children}) => {
    const [canSubmit, setCanSubmit] = React.useState(false)
    const [usersToUploadData, setUsersToUploadData] = React.useState({})
    const [isUploading, setIsUploading] = React.useState(false)
    const gridContext = React.useContext(GridReactContext)
    const setCanSubmitFunc = (value) => {
        setCanSubmit(value)
    }
    const setUsersToUploadDataFunc = (data) => {
        setUsersToUploadData(data)
    }
    const isSubmitDisabled = () => {
        return !isEmpty(get(usersToUploadData, 'errors')) || get(usersToUploadData, 'isUploading') || !get(usersToUploadData, 'users') || get(usersToUploadData, 'isThereTwoSourcesForOrgs') || get(usersToUploadData, 'isMissingSourceOfOrgs')
    }

    const triggerUploadUsers = async () => {
        setIsUploading(true)
        let sendWelcomeMessageData;
        if (get(usersToUploadData, 'sendWelcomeMessage')) {
            sendWelcomeMessageData = {
                messageTypes: get(usersToUploadData, 'selectedMessagingTypes')
            }
        }
        await createUsersForOrganizations({
            organizationIds: get(usersToUploadData, 'organizationIds'),
            users: get(usersToUploadData, 'users'),
            sendWelcomeMessage: sendWelcomeMessageData,
            onlyAddOrgsWithoutRemoving: get(usersToUploadData, 'onlyAddOrgsWithoutRemoving')
        })
        setIsUploading(false)
        gridContext.triggerTableRefresh()
    }
    return <ImportUsersReactContext.Provider value={{
        canSubmit,
        setCanSubmitFunc,
        usersToUploadData,
        setUsersToUploadDataFunc,
        isSubmitDisabled,
        isUploading,
        triggerUploadUsers
    }}>
        {children}
    </ImportUsersReactContext.Provider>
}
