import './CreateEditNewSignDocModal.scss'
import {Modal} from "../../../Smart/Modal/Modal";
import {i18n} from "../../../../Utils/i18n/i18n";
import React, {useContext, useEffect, useState} from "react";
import {FormInput} from "../../../Form/Input/FormInput";
import {Button} from "../../../Form/Button/Button";
import {buttonColors} from "../../../Pickers/InputTheme";
import {FileUploadBox} from "../../../Smart/FileUploadBox/FileUploadBox";
import {onProgress} from "../../../Smart/FileUploadBox/FileUploadBoxLib";
import {createSignDocAPI, updateSignDocAPI, uploadDocumentFileAPI} from "../../../../Services/api";
import {find, get, map} from 'lodash'
import LoadingSpinner from "../../../LoadingSpinner/LoadingSpinner";
import {useUserAdminOrgs} from "../../../Hooks/UseUserAdminOrgs";
import Chip from "@mui/material/Chip";
import {format} from "date-fns";
import AddIcon from "@mui/icons-material/Add";
import {SelectFinishDateForOrgModal} from "../../SelectFinishDateForOrgModal/SelectFinishDateForOrgModal";
import {isCanvaLink, isVimeoLink, isYoutubeLink, SignDocTypes} from "../../../SignDoc/SignDocLib";
import {SelectBox} from "../../../Pickers/SelectBox/SelectBox";
import {FeatureFlags} from "../../../../Utils/Constants";
import {FormControlLabel, Switch} from "@mui/material";
import {CompanyReactContext} from "../../../../contexts/CompanyContext";
import {
    MessageTypeSelectionButtons
} from "../../../Notifications/MessageTypeSelectionButtons/MessageTypeSelectionButtons";

const showUploadBoxForSignDocType = [SignDocTypes.READ_AND_SIGN_DOC_PDF]
const showLinkBoxForSignDocType = [SignDocTypes.WATCH_AND_SIGN_YOUTUBE_LINK, SignDocTypes.WATCH_AND_SIGN_VIMEO_LINK, SignDocTypes.CANVA_PRESENTATION]


/**
 * video links example
 * https://vimeo.com/990606130
 * https://www.youtube.com/watch?v=6rNezfIl5jc&t=51s
 * https://youtu.be/6rNezfIl5jc?si=LAbHg9ETHD4OpGVK
 * https://www.youtube.com/embed/6rNezfIl5jc?si=LAbHg9ETHD4OpGVK
 **/

const getAllowedLinkError = (linkUrl) => {

}

const forceCompletionOptions = [
    {
        value: 'forceCompletion',
        text: i18n.translate('newDocSign.modal.forceCompletion')
    },
    {
        value: 'nonForceCompletion',
        text: i18n.translate('newDocSign.modal.nonForceCompletion')
    }
]
export const CreateEditNewSignDocModal = ({
                                              newDocSignModalOpen,
                                              closeNewDocSignModal,
                                              signDoc,
                                              editMode,
                                              onAfterEditComplete,
                                              signDocType = SignDocTypes.READ_AND_SIGN_DOC_PDF,
                                              validSignDocTypes = [SignDocTypes.READ_AND_SIGN_DOC_PDF],
                                              forKnowledgeCenter

                                          }) => {
    const companyContext = useContext(CompanyReactContext)

    const getInitialSelectedForceCompletionValue = () => {
        if(!companyContext.featureFlags[FeatureFlags.KNOWLEDGE_CENTER]){
            return 'forceCompletion'
        }
        if (editMode) {
            return signDoc.doNotForceCompletion ? 'nonForceCompletion' : 'forceCompletion';
        }
        if(forKnowledgeCenter){
            return 'nonForceCompletion'
        }

        return 'forceCompletion';
    };
    const {adminOrganizationOptions, adminOrganizations, isOrgsLoading} = useUserAdminOrgs({})
    const [docName, setDocName] = useState(editMode ? signDoc.docName : '')
    const [docDescription, setDocDescription] = useState(editMode ? signDoc.description : '')
    const [progress, setProgress] = useState(0)
    const [files, setFiles] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [selectedOrganizations, setSelectedOrganizations] = useState()
    const [selectedOrganizationToAdd, setSelectedOrganizationToAdd] = useState()
    const [selectedSignDocType, setSelectedSignDocType] = useState(editMode ? signDoc.type : signDocType)
    const [linkUrl, setLinkUrl] = useState(editMode ? signDoc.linkUrl : '')
    const [signDocOptions, setSignDocOptions] = useState()
    const [linkUrlError, setLinkUrlError] = useState()
    const [notifyOrganizationUserOnNewTask, setNotifyOrganizationUserOnNewTask] = useState(false)
    const [selectedMessagingType, setSelectedMessagingType] = useState()
    const [availableOrganizations, setAvailableOrganizations] = React.useState([])
    const [selectedForceCompletionValue, setSelectedForceCompletionValue] = useState(getInitialSelectedForceCompletionValue)

    const onLinkUrlChange = (e) => {
        const linkUrl = e.target.value || ''
        setLinkUrl(linkUrl)
        //check if the link is a youtube link or youtube embed link or youtube share link
        if (isYoutubeLink({linkUrl})) {
            if (validSignDocTypes.includes(SignDocTypes.WATCH_AND_SIGN_YOUTUBE_LINK)) {
                setSelectedSignDocType(SignDocTypes.WATCH_AND_SIGN_YOUTUBE_LINK)
                setLinkUrlError(null)
            } else {
                setLinkUrlError(i18n.translate('newDocSign.modal.youtubeLinkNotAllowed'))
            }
        }
        //check if the link is a vimeo link
        else if (isVimeoLink({linkUrl})) {
            if (validSignDocTypes.includes(SignDocTypes.WATCH_AND_SIGN_VIMEO_LINK)) {
                setSelectedSignDocType(SignDocTypes.WATCH_AND_SIGN_VIMEO_LINK)
                setLinkUrlError(null)
            } else {
                setLinkUrlError(i18n.translate('newDocSign.modal.vimeoLinkNotAllowed'))
            }
        }
        else if(isCanvaLink({linkUrl})){
            if (validSignDocTypes.includes(SignDocTypes.CANVA_PRESENTATION)) {
                setSelectedSignDocType(SignDocTypes.CANVA_PRESENTATION)
                setLinkUrlError(null)
            } else {
                setLinkUrlError(i18n.translate('newDocSign.modal.canvaLinkNotAllowed'))
            }
        }
        else {
            setLinkUrlError(i18n.translate('newDocSign.modal.invalidLink'))
        }
    }

    const closeDateModal = () => {
        setSelectedOrganizationToAdd(null)
    }
    const onDocNameChange = (e) => {
        setDocName(e.target.value)
    }
    const onDocDescriptionChange = (e) => {
        setDocDescription(e.target.value)
    }
    const uploadFiles = async ({signDocId}) => {
        if (files && files.length) {
            await uploadDocumentFileAPI({files, onProgress: localOnProgress, documentId: signDocId})
        }
    }
    const createOrEditDocument = async () => {
        setIsLoading(true)
        let tmpSignDoc = signDoc
        if (editMode) {
            await updateSignDocAPI({
                docName,
                docDescription,
                linkUrl,
                selectedOrganizations,
                signDocId: tmpSignDoc.id,
                notifyOrganizationUserOnNewTask,
                notifyMessagingTypes: [selectedMessagingType],
                forceCompletion: selectedForceCompletionValue === 'forceCompletion'
            })
        } else {
            tmpSignDoc = await createSignDocAPI({
                docName,
                docDescription,
                linkUrl,
                type: selectedSignDocType,
                selectedOrganizations,
                notifyOrganizationUserOnNewTask,
                notifyMessagingTypes: [selectedMessagingType],
                forceCompletion: selectedForceCompletionValue === 'forceCompletion'
            })

        }
        if (showUploadBoxForSignDocType.includes(selectedSignDocType)) {
            await uploadFiles({signDocId: tmpSignDoc.id})
        }

        setIsLoading(false)
        onAfterEditComplete && onAfterEditComplete()
        closeNewDocSignModal()
    }

    const onSelectedDocFiles = ({files}) => {
        setFiles(files)
    }

    const localOnProgress = (event) => {
        const {progress} = onProgress(event)
        setProgress(progress)
        if (progress === 100) {
            closeNewDocSignModal()
        }
    }
    const onClickOrganization = ({organizationId, isSelected}) => () => {
        if(selectedForceCompletionValue === 'forceCompletion' && !get(selectedOrganizations[organizationId], 'finishDate') && isSelected){
            setSelectedOrganizationToAdd(organizationId)
        }
        else if (isSelected) {
            removeOrgFromSignDoc({organizationId})
        } else {
            if(selectedForceCompletionValue === 'forceCompletion'){
                setSelectedOrganizationToAdd(organizationId)
            }
            else{
                addOrgToSignDoc({organizationId, finishDate: null})
            }
        }
    }
    const addOrgToSignDoc = ({organizationId, finishDate}) => {
        let tmpSelectedOrgs = {...selectedOrganizations}
        tmpSelectedOrgs[organizationId] = {
            finishDate,
            add: true
        }
        setSelectedOrganizations(tmpSelectedOrgs)
        closeDateModal()
    }
    const removeOrgFromSignDoc = ({organizationId}) => {
        let tmpSelectedOrgs = {...selectedOrganizations}
        tmpSelectedOrgs[organizationId] = {remove: true}
        setSelectedOrganizations(tmpSelectedOrgs)

    }

    const canSubmit = () => {
        if (linkUrlError) {
            return false
        }
        for (let i = 0; i < availableOrganizations.length; i++) {
            const org = availableOrganizations[i];
            const organization = get(org, 'organization');
            const isSelected = selectedOrganizations[organization.id] && !get(selectedOrganizations, `${organization.id}.remove`);
            if (isSelected && selectedForceCompletionValue === 'forceCompletion' && !get(selectedOrganizations[organization.id], 'finishDate')) {
                return false;
            }
        }
        switch (selectedSignDocType) {
            case SignDocTypes.READ_AND_SIGN_DOC_PDF:
                if (editMode) {
                    return docName
                }
                return docName && files && files.length > 0
            case SignDocTypes.REGULAR_COURSE:
                return docName
            case SignDocTypes.WATCH_AND_SIGN_YOUTUBE_LINK:
                return docName && linkUrl
            case SignDocTypes.WATCH_AND_SIGN_VIMEO_LINK:
                return docName && linkUrl
            case SignDocTypes.CANVA_PRESENTATION:
                return docName && linkUrl
        }

    }
    useEffect(() => {
        if (editMode) {
            let text;
            if(selectedForceCompletionValue === 'forceCompletion'){
                text = i18n.translate(`signDocTypes.${signDoc.type}`)
            }
            else{
                text = i18n.translate(`signDocTypesNotForCompletion.${signDoc.type}`)
            }
            setSignDocOptions([{
                value: signDoc.type,
                text
            }])
        } else {
            setSignDocOptions(map(validSignDocTypes, (type) => {
                let text;
                if(selectedForceCompletionValue === 'forceCompletion'){
                    text = i18n.translate(`signDocTypes.${type}`)
                }
                else{
                    text = i18n.translate(`signDocTypesNotForCompletion.${type}`)
                }
                return {
                    value: type,
                    text
                }
            }))
        }

    }, [selectedForceCompletionValue]);

    useEffect(() => {
        let tmpSelectedOrgs = {}
        let tmpAvailableOrgs = []
        if (editMode) {
            map(signDoc.organizationSignDoc, (org) => {
                if (org.isValid) {
                    tmpSelectedOrgs[org.OrganizationId] = {
                        finishDate: org.finishByDate
                    }
                } else {
                    tmpSelectedOrgs[org.OrganizationId] = null
                }
                const availableOrg = find(adminOrganizations, {organization: {id: org.OrganizationId}})
                if(availableOrg){
                    tmpAvailableOrgs.unshift(availableOrg)
                }
            })
        } else {
            map(adminOrganizations, (org) => {
                tmpSelectedOrgs[org.OrganizationId] = null
                const availableOrg = find(adminOrganizations, {organization: {id: org.OrganizationId}})
                if(availableOrg){
                    tmpAvailableOrgs.unshift(availableOrg)
                }
            })

        }
        setAvailableOrganizations(tmpAvailableOrgs)
        setSelectedOrganizations(tmpSelectedOrgs)
        setIsLoading(false)


    }, [adminOrganizations]);

    const onSelectedSignDocTypeOption = (e) => {
        const newSelectedSignDocType = e.target.value
        setSelectedSignDocType(newSelectedSignDocType)
        if (newSelectedSignDocType === SignDocTypes.WATCH_AND_SIGN_YOUTUBE_LINK) {
            if (!isYoutubeLink({videoUrl: linkUrl})) {
                setLinkUrlError(i18n.translate('newDocSign.modal.invalidLink'))
            } else {
                setLinkUrlError(null)
            }
        } else if (newSelectedSignDocType === SignDocTypes.WATCH_AND_SIGN_VIMEO_LINK) {
            if (!isVimeoLink({videoUrl: linkUrl})) {
                setLinkUrlError(i18n.translate('newDocSign.modal.invalidLink'))
            } else {
                setLinkUrlError(null)
            }
        }
    }
    const onSelectForceCompletion = (e) => {
        if(e.target.value === 'nonForceCompletion'){
            setNotifyOrganizationUserOnNewTask(false)
        }
        setSelectedForceCompletionValue(e.target.value)
    }


    const onSendNewTaskForUserChange = (e) => {
        setNotifyOrganizationUserOnNewTask(e.target.checked)
    }

    return <Modal
        open={newDocSignModalOpen}
        onClose={closeNewDocSignModal}>
        <div className="CreateEditNewDocSignModal modalWrapper">
            <div className="modalTitle">
                <div>{i18n.translate('newDocSign.modal.title')}</div>
            </div>
            <div className="modalContent">
                {isLoading ? <LoadingSpinner/> : ''}
                <div className="inputsContainer">
                    <div className="typeWrapper row">
                        <div className={companyContext.featureFlags[FeatureFlags.KNOWLEDGE_CENTER] ? `col-50 margin-left` : `col-100`}>
                            <SelectBox options={signDocOptions} value={selectedSignDocType}
                                       disabled={(signDocOptions && signDocOptions.length <= 1) || editMode}
                                       label={i18n.translate('newDocSign.modal.selectSignDocType')}
                                       onChange={onSelectedSignDocTypeOption}/>
                        </div>

                        {companyContext.featureFlags[FeatureFlags.KNOWLEDGE_CENTER] ? <div className="col-50 margin-right">
                            <SelectBox options={forceCompletionOptions} value={selectedForceCompletionValue}
                                       disabled={forKnowledgeCenter}
                                       label={i18n.translate('newDocSign.modal.selectSignDocType')}
                                       onChange={onSelectForceCompletion}/>
                        </div> : ''}

                    </div>
                    <FormInput
                        required
                        dontShowErrorMessage
                        id="docName"
                        label={i18n.translate('newDocSign.modal.docNameLabel')}
                        className="newDocSignInput"
                        value={docName}
                        onChange={onDocNameChange}
                    />

                    <FormInput
                        dontShowErrorMessage
                        id="name"
                        label={i18n.translate('newDocSign.modal.docDescriptionLabel')}
                        className="newDocSignInput"
                        value={docDescription}
                        onChange={onDocDescriptionChange}
                    />
                    {showLinkBoxForSignDocType.includes(selectedSignDocType) ? <FormInput
                        error={linkUrlError}
                        dontShowErrorMessage
                        required
                        id="linkUrl"
                        label={i18n.translate('newDocSign.modal.linkUrl')}
                        className="newDocSignInput"
                        value={linkUrl}
                        onChange={onLinkUrlChange}/> : ''}
                    {linkUrlError && linkUrl ? <div className="linkUrlError">{linkUrlError}</div> : ''}
                </div>
                {showUploadBoxForSignDocType.includes(selectedSignDocType) ? <div className="uploadFileContainer">
                    <FileUploadBox
                        uploadFiles={onSelectedDocFiles}
                        maxSize={50000000}
                        accept={{
                            'application/pdf': ['.pdf']
                        }}
                        supportedFileTypesForNotifications={['PDF']}>
                        <div>{files && files.length > 0 ? get(files, '[0].path') : ''}</div>
                        <div>
                            {(progress && progress !== 100) ? i18n.translate('uploadBox.uploading') + progress + '%' : ''}
                        </div>
                        <div>
                            {(progress && progress === 100) ? i18n.translate('uploadBox.uploaded') : ''}
                        </div>
                        <div>
                            <div>{(!files || !files.length > 0) ? i18n.translate('newDocSign.modal.dropSignDocHere') : ''}</div>
                        </div>
                    </FileUploadBox>
                </div> : ''}
                <div className="organizationChipsWrapper">
                    {map(availableOrganizations, (org) => {
                        const organization = get(org, 'organization')
                        const isSelected = selectedOrganizations[organization.id] && !get(selectedOrganizations, `${organization.id}.remove`)
                        const orgFinishDate =
                            selectedForceCompletionValue === 'forceCompletion' ?
                                get(selectedOrganizations[organization.id], 'finishDate') : null
                        let icon
                        if(selectedForceCompletionValue === 'forceCompletion' && !orgFinishDate && isSelected){
                            icon = <AddIcon/>
                        }
                        else if(isSelected){
                            icon = null
                        }
                        else {
                            icon = <AddIcon/>
                        }
                        return <div className="organizationChip">
                            <Chip key={organization.id}
                                  className={`${isSelected ? 'selectedChip' : ''} ${selectedForceCompletionValue === 'forceCompletion' && !orgFinishDate ? 'missingFinishDate' : ''}`}
                                  deleteIcon={icon}
                                  label={`${organization.orgName}${orgFinishDate ? ` - ${format(orgFinishDate, 'dd/MM/yyyy')}` : ''}`}
                                  onDelete={onClickOrganization({organizationId: organization.id, isSelected})}/>
                        </div>
                    })}
                </div>
                {companyContext.featureFlags[FeatureFlags.NOTIFY_NEW_TASK_TO_USER] && selectedForceCompletionValue === 'forceCompletion' ?
                    <div className="notifyUserWrapper">
                        <FormControlLabel control={<Switch
                            checked={notifyOrganizationUserOnNewTask}
                            onChange={onSendNewTaskForUserChange}
                        />} label={i18n.translate('newDocSign.modal.notifyUsersOfNewTask')}/>
                    </div> : ''}
                {notifyOrganizationUserOnNewTask ? <div className="messagingTypeWrapper">
                    <MessageTypeSelectionButtons onSelectedMessageTypeChange={setSelectedMessagingType}
                                                 selectedMessagingTypes={[selectedMessagingType]}/>
                </div> : ''}
            </div>
            <div className="modalFooter">
                <Button
                    color={buttonColors.GREY}
                    onClick={closeNewDocSignModal}
                    text={i18n.translate('general.cancel')}
                />
                <Button
                    color={buttonColors.YELLOW}
                    disabled={!canSubmit()}
                    onClick={createOrEditDocument}
                    text={editMode ? i18n.translate('general.saveChanges') : i18n.translate(`newDocSign.modal.createDocument`)}
                />
            </div>
        </div>
        {selectedOrganizationToAdd ?
            <SelectFinishDateForOrgModal
                onClose={closeDateModal}
                availableOrgs={map(adminOrganizations, (org) => {
                    return org.organization
                })}
                organizationId={selectedOrganizationToAdd}
                key={selectedOrganizationToAdd}
                onSave={addOrgToSignDoc}/> : ""}
    </Modal>;
}