import './SuperAdminCreateNewCustomerModal.scss';
import React from "react";
import {FormInput} from "../../Form/Input/FormInput";
import {i18n} from "../../../Utils/i18n/i18n";
import {Button} from "../../Form/Button/Button";
import {buttonColors} from "../../Pickers/InputTheme";
import {SA_createCompany} from "../../../Services/SA_api";
import {GridReactContext} from "../../../Utils/DataGridWrapper/GridContext";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import {Modal} from "../../Smart/Modal/Modal";


export const SuperAdminCreateNewCustomerModal = ({createCustomerModalOpen, closeCreateCustomerModal, onUserCreated}) => {
    const [companyName, setCompanyName] = React.useState()
    const [companyUsersPrefix, setCompanyUserPrefix] = React.useState()
    const [identifier, setIdentifier] = React.useState()
    const [contactPhone, setContactPhone] = React.useState()
    const [contactEmail, setContactEmail] = React.useState()
    const [userIdentifier, setUserIdentifier] = React.useState()
    const [userPassword, setUserPassword] = React.useState()
    const [userFirstName, setUserFirstName] = React.useState()
    const [userLastName, setUserLastName] = React.useState()
    const [errorPrefix, setErrorPrefix] = React.useState(false)
    const [contactFirstName, setContactFirstName] = React.useState()
    const [contactLastName, setContactLastName] = React.useState()
    const [isLoading, setIsLoading] = React.useState(false)

    const gridContext = React.useContext(GridReactContext)


    const onEditCompanyName = (e) => {
        setCompanyName(e.target.value)
    }
    const onEditCompanyUserPrefix = (e) => {
        //check if it only contains english letters and numbers
        const trimmed = e.target.value.replace(/[^a-zA-Z0-9]/g, '')
        if (trimmed !== e.target.value) {
            setErrorPrefix(true)
        } else {
            setErrorPrefix(false)
        }
        //capitalize
        setCompanyUserPrefix(e.target.value.toUpperCase())
    }
    const onEditIdentifier = (e) => {
        setIdentifier(e.target.value)
    }
    const onEditContactPhone = (e) => {
        setContactPhone(e.target.value)
    }
    const onEditContactEmail = (e) => {
        setContactEmail(e.target.value)
    }
    const onEditUserIdentifier = (e) => {
        //remove special characters
        let trimmed = e.target.value.replace(/[^a-zA-Z0-9]/g, '')
        //remove spaces
        trimmed = trimmed.replace(/\s/g, '')
        setUserIdentifier(trimmed)
    }
    const onEditUserPassword = (e) => {
        setUserPassword(e.target.value)
    }
    const onEditUserFirstName = (e) => {
        setUserFirstName(e.target.value)
    }
    const onEditUserLastName = (e) => {
        setUserLastName(e.target.value)
    }
    const onEditContactFirstName = (e) => {
        setContactFirstName(e.target.value)
    }
    const onEditContactLastName = (e) => {
        setContactLastName(e.target.value)
    }
    const onCreateCompany = async () => {
        setIsLoading(true)

        const res = await SA_createCompany({
            companyName,
            companyUsersPrefix,
            identifier,
            contactPhone,
            contactEmail,
            contactFirstName,
            contactLastName,
            userIdentifier,
            userPassword,
            userFirstName,
            userLastName
        })
        console.log(res)
        setIsLoading(false)
        if (!res.error) {
            onUserCreated()
            gridContext.triggerTableRefresh()
        }
    }

    const buttonDisabled = !companyName || !companyUsersPrefix || !identifier || !userIdentifier || !userPassword || !userFirstName || !userLastName || errorPrefix
    return <Modal
        open={createCustomerModalOpen}
        onClose={closeCreateCustomerModal}>
        <div className="SuperAdminCreateNewCustomer modalWrapper">
            <div className="modalTitle">
                <div>{i18n.translate('superAdmin.createNewCustomer.modalTitle')}</div>
            </div>
            <div className="modalContent">
                <div className="editContentWrapper">
                    <div className="detailsTitle">
                        <div>{i18n.translate('superAdmin.createNewCustomer.detailsTitle')}</div>
                    </div>
                    {isLoading ? <LoadingSpinner/> : ""}
                    <div className="details">
                        <div className="detailsRow">
                            <FormInput
                                required
                                id={`companyName`}
                                label={i18n.translate('superAdmin.createNewCustomer.companyName')}
                                className=""
                                value={companyName}
                                onChange={onEditCompanyName}
                            />
                            <FormInput
                                required
                                id={`companyUsersPrefix`}
                                label={i18n.translate('superAdmin.createNewCustomer.userPrefix')}
                                error={errorPrefix && i18n.translate('superAdmin.createNewCustomer.userPrefixError')}
                                className=""
                                value={companyUsersPrefix}
                                onChange={onEditCompanyUserPrefix}
                            />
                            <FormInput
                                required
                                id={`identifier`}
                                label={i18n.translate('superAdmin.createNewCustomer.identifier')}
                                className=""
                                value={identifier}
                                onChange={onEditIdentifier}
                            />
                        </div>


                        <div className="detailsRow">
                            <FormInput
                                id={`contactPhone`}
                                label={i18n.translate('superAdmin.createNewCustomer.contactPhone')}
                                className=""
                                value={contactPhone}
                                onChange={onEditContactPhone}
                            />
                            <FormInput
                                id={`contactEmail`}
                                label={i18n.translate('superAdmin.createNewCustomer.contactEmail')}
                                className=""
                                value={contactEmail}
                                onChange={onEditContactEmail}
                            />
                            <FormInput
                                id={`contactFirstName`}
                                label={i18n.translate('superAdmin.createNewCustomer.contactFirstName')}
                                className=""
                                value={contactFirstName}
                                onChange={onEditContactFirstName}/>
                            <FormInput
                                id={`contactLastName`}
                                label={i18n.translate('superAdmin.createNewCustomer.contactLastName')}
                                className=""
                                value={contactLastName}
                                onChange={onEditContactLastName}/>
                        </div>
                    </div>
                    <div className="userCreationTitle">
                        <div>{i18n.translate('superAdmin.createNewCustomer.userCreationTitle')}</div>
                    </div>
                    <div className="userCreation">
                        <div className="detailsRow">
                            <div className="userIdentifierWithPrefix">
                                <FormInput
                                    required
                                    id={`userIdentifier`}
                                    disabled={!companyUsersPrefix || companyUsersPrefix === ''}
                                    label={i18n.translate('superAdmin.createNewCustomer.userIdentifier')}
                                    className="ltrInput"
                                    value={userIdentifier}
                                    onChange={onEditUserIdentifier}
                                />

                                <div className="inputPrefix">{companyUsersPrefix ? `${companyUsersPrefix}-` : ''}</div>
                            </div>
                            <FormInput
                                type="password"
                                required
                                id={`userPassword`}
                                label={i18n.translate('superAdmin.createNewCustomer.userPassword')}
                                className="ltrInput"
                                value={userPassword}
                                onChange={onEditUserPassword}/>


                        </div>
                        <div className="detailsRow">
                            <FormInput
                                required
                                id={`userFirstName`}
                                label={i18n.translate('superAdmin.createNewCustomer.userFirstName')}
                                value={userFirstName}
                                onChange={onEditUserFirstName}/>
                            <FormInput
                                required
                                id={`userLastName`}
                                label={i18n.translate('superAdmin.createNewCustomer.userLastName')}
                                value={userLastName}
                                onChange={onEditUserLastName}/>
                        </div>

                    </div>
                    <div className="userNameNote">
                        <div>{companyUsersPrefix && userIdentifier ? i18n.translate('superAdmin.createNewCustomer.userNameNote', {
                                context: {
                                    companyUsersPrefix,
                                    userIdentifier
                                }
                            }) :
                            i18n.translate('superAdmin.createNewCustomer.userNameNoteNoPrefix')}</div>
                    </div>
                </div>
            </div>
            <div className="modalFooter">
                <Button
                    color={buttonColors.YELLOW}
                    onClick={closeCreateCustomerModal}
                    text={i18n.translate('general.close')}
                />
                <Button
                    disabled={buttonDisabled}
                    color={buttonColors.GREY}
                    onClick={onCreateCompany}
                    text={i18n.translate('superAdmin.createNewCustomer.createCompany')}
                />

            </div>
        </div>
    </Modal>
}